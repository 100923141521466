import { useMemo, useEffect, useState, useCallback, useRef, Fragment } from 'react'
import 'intl-pluralrules'
import App from 'next/app'
import Head from 'next/head'
import Script from 'next/script'
import dynamic from 'next/dynamic'
import getConfig from 'next/config'
import { useRouter } from 'next/router'
import { appWithTranslation } from 'next-i18next'
import { shouldPolyfill } from '@formatjs/intl-numberformat/should-polyfill'
import { shouldPolyfill as shouldPolyfillLocale } from '@formatjs/intl-locale/should-polyfill'
import posthog from 'posthog-js'
import type { ReactNode } from 'react'
import smoothscroll from 'smoothscroll-polyfill'
import type { AppProps, AppContext } from 'next/app'
import cookie from 'cookie'
import { ApolloProvider } from '@apollo/client'
import { ErrorBoundary } from 'react-error-boundary'
import qs from 'query-string'

import { YellowChatWidget } from 'components/chat-widget/yellowai'
import { ModalOverlay } from 'components/modal-overlay'
import { ErrorFallback } from 'components/error-fallback'
import { AccountCreatedToast } from 'components/account-created-toast'
import { MarketingConsentModal } from 'components/marketing-consent-modal'
import { GoogleOneTap } from 'components/google-one-tap'
import { ExitIntentEvents } from 'components/exit-intent-events'
import { ToastContainer } from 'components/toast-container'
import { GoogleTagManager } from 'components/google-tag-manager'
import { CampaignModal } from 'components/campaign-modal'

import { WishlistModal } from 'page-modules/product/wishlist/modal'

import { useCurrencyForCachedPage } from 'lib/hooks/useCurrencyForCachedPage'
import { useUserLocation } from 'lib/hooks/useUserLocation'
import { requestHandler, useAppGetInitialProps } from 'lib/hooks/useAppGetInitialProps'
import { isRouteMatch } from 'lib/hooks/useRouteMatch'
import { useShopback } from 'lib/hooks/useShopback'
import useEventSessionAndRef from 'lib/hooks/useEventSessionAndRef'

import { removeExpiredStaticFields } from 'lib/utils/booking'
import getStripe from 'lib/utils/stripe'
import { sift } from 'lib/utils/sift'
import {
  createActivitySession,
  getBrowserCookie,
  generatePartnerSession,
  getQueryLists,
  isBrowser,
  isServer,
  logError,
  setBrowserCookie,
} from 'lib/utils'
import { FingerprintContextProvider } from 'lib/context/fingerprint-context'
import { HeaderDataProvider } from 'lib/context/header-data-context'
import { GlobalContextProvider, IGlobalContext, initialGlobalArgs } from 'lib/context/global-context'
import { getStorageItem } from 'lib/utils/web-storage'
import { initializeApollo, useApollo } from 'lib/apollo-client'
import { AppDataProvider } from 'lib/context/app-data-context'
import { AuthProvider } from 'lib/context/auth-context'
import { WishlistProvider } from 'lib/context/wishlist-context'
import { LayoutProps, NextPageWithLayout } from 'lib/@Types/layout'
import { DestinationCountryProvider } from 'lib/context/destination-country-context'
import { DestinationByRegionProvider } from 'lib/context/destination-by-region-context'

import {
  COOKIES_CURRENCY,
  COOKIES_DS_SESSION_ID,
  SENTRY_ERROR_IGNORE_REGEX,
  HEADER_CLOUDFRONT_LATITUDE,
  HEADER_CLOUDFRONT_LONGITUDE,
  HEADER_CLOUDFRONT_COUNTRY,
  HEADER_REFERER,
  ACTIVITY_LOG,
  ACTIVITY_LOG_EXPIRY,
  COOKIES_RECENT_PDP_VISIT,
  COOKIES_RECENT_PDP_VISIT_EXPIRY,
  HEADER_CLOUDFRONT_CITY,
  PARTNER_DEFAULT_CURRENCY,
  PARTNER_LIST,
  COOKIES_PARTNER_SESSION_ID,
  APP_TO_PAGE_SSR_DATA,
  IS_MOBILE_REGEX,
  IS_TABLET_REGEX,
  HEADER_USER_AGENT,
} from 'lib/constants'
import { PRODUCT_ROUTE, STATIC_ROUTES } from 'lib/constants/routes'

import { GLOBAL_ARGS_QUERY } from 'gql'

import localeInfo from '../locale-info.json'
import { useYellowAi } from '../lib/hooks/useYellowAi'

import './global.scss'
import 'brand-assets/icon-lib/icon-custom-styles.scss'

const { publicRuntimeConfig } = getConfig()

if (isBrowser) {
  smoothscroll.polyfill()
}

const AuthModal = dynamic(() => import('components/auth-modal').then((module: any) => module.AuthModal), {
  ssr: false,
})
const ProgressBar = dynamic(
  () => import('../lib/context/progress-bar-context').then((module: any) => module.ProgressBar),
  {
    ssr: false,
  }
)
const PostHogPageView = dynamic(
  () => import('components/posthog-page-view').then((module: any) => module.PostHogPageView),
  {
    ssr: false,
  }
)

interface EnhancedAppProps extends AppProps {
  currentCurrency: string
  globalArgs: GlobalArgs
  geoLocation: GeoLocation
  Component: NextPageWithLayout
  ssrIsMobile: boolean
  ssrIsTablet: boolean
}

if (isBrowser) {
  createActivitySession()
  // fix: https://stackoverflow.com/questions/51625973/datalayer-push-not-working-after-gtm-script
  window.dataLayer = window.dataLayer || []
}

if (isBrowser) {
  // remove expired data from local storage for static fields
  removeExpiredStaticFields()
}

if (isBrowser) {
  const _activityLog: any = getStorageItem(ACTIVITY_LOG)
  let isPDPVisitedRecently = false

  try {
    const jsonActivityLog = JSON.parse(_activityLog) || {}

    isPDPVisitedRecently = !!jsonActivityLog?.products?.filter?.(
      ({ created }: { created: Date }) =>
        new Date().getTime() - new Date(created).getTime() <= ACTIVITY_LOG_EXPIRY
    )?.length
  } catch {}
  setBrowserCookie(COOKIES_RECENT_PDP_VISIT, `${isPDPVisitedRecently}`, COOKIES_RECENT_PDP_VISIT_EXPIRY)
}

if (isBrowser) {
  const searchParams = qs.parse(window.location.search)
  // to best leverage Stripe’s advanced fraud functionality, include this script on every page
  // https://stripe.com/docs/js/including
  // Dont load in partner view
  if (PARTNER_LIST.includes(searchParams?.partnerId as string)) {
    getStripe()
  }

  const [browserLocale] = window?.location?.pathname?.split?.('/')?.filter(Boolean)
  const validBrowserLocale =
    localeInfo.supportedLocales?.find?.((l) => l?.toLowerCase?.() === browserLocale?.toLowerCase?.()) ||
    localeInfo.fallbackLocale
  // @ts-ignore
  const polyfillLocale = localeInfo.localeToPolyfillLocale[validBrowserLocale] || validBrowserLocale

  const unsupportedLocale = shouldPolyfill(polyfillLocale)
  const includeLocalePolyfill = shouldPolyfillLocale()

  ;(async () => {
    if (!unsupportedLocale && !includeLocalePolyfill) return

    // polyfill added for ios 12 iphone 7 device
    // docs https://formatjs.io/docs/polyfills/intl-numberformat
    try {
      // eslint-disable-next-line @next/next/no-assign-module-variable
      const module = await import('./intl-polyfills')
      module.default({ unsupportedLocale, includeLocalePolyfill })
    } catch {
      logError(`[${unsupportedLocale}] polyfill not supported (original: ${polyfillLocale})`)
    }
  })()
}

if (isBrowser && process.env.NEXT_PUBLIC_POSTHOG_KEY) {
  // We will add posthog_key on production only for now.
  // As we dont have sandbox account for posthog and we want to test
  // on dev, we can add key for temoprary purpose and remove later
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || 'https://app.posthog.com',
    capture_pageview: false,
  })
}

function MyApp({
  Component,
  pageProps,
  globalArgs,
  geoLocation,
  currentCurrency,
  ssrIsMobile,
  ssrIsTablet,
}: EnhancedAppProps) {
  const router = useRouter()
  useAppGetInitialProps({ globalArgs })

  const { currency: currencyToSet } = useCurrencyForCachedPage({ serverCurrency: currentCurrency })
  const partnerSession = JSON.parse(getBrowserCookie(COOKIES_PARTNER_SESSION_ID) || '{}')
  const isPartnerView = partnerSession?.partnerId || partnerSession?.sessionId
  const [isMobileView, setIsMobileView] = useState<boolean>(ssrIsMobile)
  const [isTabletView, setIsTabletView] = useState(ssrIsTablet)
  const yellowAi = useYellowAi({
    shouldRender: isServer ? false : !isMobileView || !isPartnerView,
  })

  const unchangedDataRef = useRef<{ globalArgs: GlobalArgs; geoLocation: GeoLocation }>({
    globalArgs,
    geoLocation,
  })

  const userGeoLocation = useUserLocation({ ssrGeoLocation: geoLocation })
  const unchangedData = useMemo(() => {
    if (Object.keys(globalArgs || {}).length) unchangedDataRef.current.globalArgs = globalArgs
    if (userGeoLocation && Object.keys(userGeoLocation).length)
      unchangedDataRef.current.geoLocation = userGeoLocation

    return unchangedDataRef.current
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const client = useApollo({ geoLocation: unchangedDataRef.current?.geoLocation })

  const [modalOptions, setModalOptions] = useState<ModalOptions>({
    isActive: false,
    content: null,
    withCloseBtn: false,
    closeOnOverlayClick: true,
    closeOnEsc: true,
  })

  useEventSessionAndRef()
  useShopback()

  // workaround for fixing the css style sheet removal of dynamically imported components
  // more details here: https://github.com/vercel/next.js/issues/17464
  // if any style conflict issue found because of this,
  // remove this useEffect and import searchAutocomplete component without dynamic import
  useEffect(() => {
    const elements = document.querySelectorAll('head link[rel=stylesheet]')
    if (elements) {
      for (const el of elements as any) {
        if (el?.hasAttribute('data-n-p')) {
          el?.removeAttribute('data-n-p')
        }
      }
    }
  }, [])

  useEffect(() => {
    const { phoneQueryList, tabletQueryList } = getQueryLists()
    const isMobileView = phoneQueryList.matches
    const isTabletView = tabletQueryList.matches
    setIsMobileView(isMobileView)
    setIsTabletView(isTabletView)

    const phoneQueryHandler = (queryList: MediaQueryListEvent) => {
      const isMobileView = queryList.matches
      setIsMobileView(isMobileView)
    }
    const tabletQueryHandler = (queryList: MediaQueryListEvent) => {
      const isTabletView = queryList.matches
      setIsTabletView(isTabletView)
    }

    try {
      phoneQueryList.addEventListener('change', phoneQueryHandler)
      tabletQueryList.addEventListener('change', tabletQueryHandler)
    } catch {
      // required for Safari v13.1.3
      phoneQueryList.addListener(phoneQueryHandler)
      tabletQueryList.addListener(tabletQueryHandler)
    }

    return () => {
      try {
        phoneQueryList.removeEventListener('change', phoneQueryHandler)
        tabletQueryList.removeEventListener('change', tabletQueryHandler)
      } catch {
        // required for Safari v13.1.3
        phoneQueryList.removeListener(phoneQueryHandler)
        tabletQueryList.removeListener(tabletQueryHandler)
      }
    }
  }, [])

  // sift page change event
  useEffect(() => {
    const handleRouteChange = (_url: string, { shallow }: { shallow: 'boolean' }) => {
      if (shallow) return

      sift.events.pageView()
    }
    router.events.on('routeChangeStart', handleRouteChange)

    return () => router.events.off('routeChangeStart', handleRouteChange)
  }, [router.events])

  useEffect(() => {
    // This prevent page jumping when navigating back via browser back button
    if ('scrollRestoration' in history) {
      history.scrollRestoration = 'manual'
    }
  }, [])

  const openModal = (options: ModalOptions) => setModalOptions({ ...options, isActive: true })

  const closeModal = useCallback(() => setModalOptions({ ...modalOptions, isActive: false }), [modalOptions])

  const globalContext = useMemo<IGlobalContext>(() => {
    return {
      ...unchangedData,
      currentCurrency: currencyToSet,
      openModal,
      closeModal,
      isMobileView,
      isTabletView,
      yellowAi,
      // When an existing user logs in, the application performs a router.replace using the user's locale from their profile.
      // This action resets the app state, causing global arguments to become undefined, which briefly shows an error state.
      // To prevent this, initialize global arguments with initialGlobalArgs before the page refresh triggered by window.reload.
      globalArgs: unchangedData.globalArgs || initialGlobalArgs,
    }
  }, [closeModal, currencyToSet, isMobileView, isTabletView, yellowAi, unchangedData])

  // Use the layout defined at the page level, if available
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const getLayout = Component.getLayout ?? ((page: ReactNode, layoutProps?: LayoutProps) => page)
  const layoutProps = { isMobileView }

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          name="apple-itunes-app"
          content="app-id=6480498977, app-argument=https://apps.apple.com/sg/app/pelago-by-singapore-airlines/id6480498977"
        />
        <link rel="manifest" href="/manifest.json" />
      </Head>

      <ApolloProvider client={client}>
        <AuthProvider>
          <GlobalContextProvider value={globalContext}>
            <DestinationCountryProvider>
              <AppDataProvider
                ssrPartnerSessionInfo={partnerSession}
                isPromoEnabled={(pageProps as any)?.product?.isPromoEnabled}
              >
                <WishlistProvider>
                  <HeaderDataProvider>
                    <FingerprintContextProvider>
                      <GoogleTagManager />
                      <Script
                        src={process.env.NEXT_PUBLIC_SENTRY_MIN}
                        strategy="afterInteractive"
                        crossOrigin="anonymous"
                        data-lazy="no"
                        onLoad={() => {
                          const sentryAdditionalParams: any = {}
                          const isProdEnv = process.env.NEXT_PUBLIC_APP_ENV === 'prod'

                          sentryAdditionalParams.tracePropagationTargets = isProdEnv
                            ? [/^https:\/\/traveller-core.pelago\.co\/graphql/]
                            : [
                                'localhost',
                                /^https:\/\/traveller-core.dev.pelago\.co\/graphql/,
                                /^https:\/\/traveller-core.stage.pelago\.co\/graphql/,
                                /^https:\/\/traveller-core.qa.pelago\.co\/graphql/,
                              ]

                          window?.Sentry?.init({
                            ...sentryAdditionalParams,
                            release: `${publicRuntimeConfig?.appName}@${publicRuntimeConfig?.appVersion}`,
                            environment: process.env.NEXT_PUBLIC_APP_ENV,
                            hideSourceMaps: process.env.NODE_ENV === 'production',
                            widenClientFileUpload: true,
                            // Sampling rate for Errors (exceptions)
                            // send 2% errors on Prod and 100% errors on non-prod
                            sampleRate: isProdEnv ? 0.02 : 1.0,
                            // Sampling rate for Performance Monitoring (web vitals, transactions)
                            // send 10% Performance Monitoring trace on Prod and 100% trace on non-prod
                            tracesSampleRate: isProdEnv ? 0.2 : 1.0,
                            beforeSend: (event: any, hint: any) => {
                              const errorMsg = hint?.originalException?.message

                              if (
                                errorMsg &&
                                SENTRY_ERROR_IGNORE_REGEX.some((regex) => regex.test(errorMsg))
                              ) {
                                return null
                              }
                              return event
                            },
                          })
                        }}
                      />
                      <Script
                        src="https://cdn.sift.com/s.js"
                        strategy="afterInteractive"
                        onLoad={() => {
                          const _user_id = ''
                          const _session_id = getBrowserCookie(COOKIES_DS_SESSION_ID) // Set to a unique session ID for the visitor's current browsing session.

                          const _sift = (window._sift = window._sift || [])
                          _sift.push(['_setAccount', process.env.NEXT_PUBLIC_SIFT_BEACON])
                          _sift.push(['_setUserId', _user_id])
                          _sift.push(['_setSessionId', _session_id])
                          _sift.push(['_trackPageview'])
                        }}
                      />
                      <ErrorBoundary FallbackComponent={ErrorFallback} onError={logError}>
                        <ProgressBar />
                        <PostHogPageView />
                        <DestinationByRegionProvider>
                          {getLayout(<Component {...pageProps} />, layoutProps)}
                        </DestinationByRegionProvider>
                        <AuthModal />
                        <MarketingConsentModal />
                        <AccountCreatedToast />
                        <ModalOverlay {...modalOptions} onClose={closeModal} />
                        <ToastContainer />
                        <WishlistModal />
                        {!isPartnerView && <GoogleOneTap />}
                        <ExitIntentEvents />
                        {process.env.NODE_ENV === 'production' && <YellowChatWidget />}
                        <CampaignModal />
                      </ErrorBoundary>
                    </FingerprintContextProvider>
                  </HeaderDataProvider>
                </WishlistProvider>
              </AppDataProvider>
            </DestinationCountryProvider>
          </GlobalContextProvider>
        </AuthProvider>
      </ApolloProvider>
    </>
  )
}

const getGlobalData = async ({ apolloClient, appContext }: { apolloClient: any; appContext: AppContext }) => {
  // static pages doesnt use global args data and the page will be cached. So do not have to call the query during build time
  const isStaticRoute = STATIC_ROUTES.includes(appContext.ctx.pathname)
  const { data: response } = isStaticRoute
    ? await Promise.resolve({
        data: {
          campaign: {},
          tags: [],
          categories: [],
          currencies: [],
          constants: {},
          locales: [],
          rewardsBanner: {},
          whitelabelSolutions: [],
          appTouchpointsData: {},
        },
      })
    : await apolloClient.query({
        query: GLOBAL_ARGS_QUERY,
      })

  const headers = appContext.ctx.req?.headers

  const latitude = headers?.[HEADER_CLOUDFRONT_LATITUDE] as string
  const longitude = headers?.[HEADER_CLOUDFRONT_LONGITUDE] as string
  const countryCode = headers?.[HEADER_CLOUDFRONT_COUNTRY] as string
  const cityName = headers?.[HEADER_CLOUDFRONT_CITY] as string

  const globalData: { globalArgs: GlobalArgs; geoLocation: GeoLocation } = {
    globalArgs: response,
    geoLocation: {
      latitude: parseFloat(latitude),
      longitude: parseFloat(longitude),
      countryCode,
      cityName,
    },
  }

  return globalData
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  const { cookie: reqHeaderCookies } = appContext.ctx.req?.headers || {}
  const reqCookies = cookie.parse(reqHeaderCookies || '')

  const apolloClient = initializeApollo(appContext.ctx)

  const appProps = await App.getInitialProps(appContext)

  const [partnerSessionInfo, hasPartnerSessionInfo] = generatePartnerSession({
    queryParams: appContext.router.query,
    cookies: reqCookies,
  })

  const currentCurrency: string | null = hasPartnerSessionInfo
    ? PARTNER_DEFAULT_CURRENCY
    : reqCookies[COOKIES_CURRENCY]

  // do not have to query for global args from client side as the data is already loaded on the initial load
  // appContext.ctx.req?.url?.startsWith('/_next/data') => all the getServerSideProps request from client side
  if (appContext.ctx.req?.url?.startsWith('/_next/data') || isBrowser) {
    const isArticleRoute = appContext.ctx.pathname.includes('/articles/')
    let globalData: { globalArgs: GlobalArgs } | null = null

    if (isArticleRoute) {
      globalData = await getGlobalData({ apolloClient, appContext })
    }
    return {
      ...appProps,
      currentCurrency,
      ssrPartnerSessionInfo: partnerSessionInfo,
      ...(globalData ? globalData : {}),
    }
  }

  const headers = appContext.ctx.req?.headers
  const countryCode = headers?.[HEADER_CLOUDFRONT_COUNTRY] as string

  const globalData = await getGlobalData({ apolloClient, appContext })
  const {
    globalArgs: { locales = [], currencies = [] },
  } = globalData || {}

  const userAgent = appContext.ctx.req?.headers?.[HEADER_USER_AGENT] || ''
  let ssrIsMobile = Boolean(userAgent.match(IS_MOBILE_REGEX))
  let ssrIsTablet = Boolean(userAgent.match(IS_TABLET_REGEX))

  const isProductDetailPage = isRouteMatch(appContext.ctx.pathname, PRODUCT_ROUTE)
  if (!isProductDetailPage) {
    const isAuthCallback = !!appContext.ctx.req?.url?.includes?.('/callback')
    const cookiesToSet = await requestHandler({
      queryParams: appContext.router.query,
      serverCookies: reqCookies,
      currencies,
      ssrKeyValues: { countryCode, referer: headers?.[HEADER_REFERER] },
      skip: { attribution: isAuthCallback },
    })

    // set these cookies in client side
    // this will be used by apollo client later too
    cookiesToSet.length && appContext.ctx.res?.setHeader('Set-Cookie', cookiesToSet)
  } else {
    ssrIsMobile = true
    ssrIsTablet = false
  }

  // when PDP page server side, currency will be respected according to locale in URL
  // with this currency setup page will be cached in cloudfront, and later currency & price will be changed client side based on user's preference
  if (isProductDetailPage && isServer) {
    const currency = locales.find(
      (locale: GlobalLocale) => locale.localeLabel === appContext.router.locale
    )?.currency
    // add this ssr currency in req header so that page(pdp) server side method can read this
    // currently there is no direct option available in nextjs where data from _app server method can share to page server method hence below hack applied
    if (currency && appContext.ctx.req?.headers)
      appContext.ctx.req.headers[APP_TO_PAGE_SSR_DATA.SSR_CURRENCY] = currency
  }

  return {
    ...globalData,
    ...appProps,
    ssrIsMobile,
    ssrIsTablet,
    currentCurrency,
    ssrPartnerSessionInfo: partnerSessionInfo,
  }
}

// @ts-ignore
export default appWithTranslation(MyApp)
